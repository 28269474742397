import React, { useMemo } from 'react';

import hounddogLogoBlack from '/logo-b.png';

import { cn } from '~/lib/utils';

import LoadingDots from './loading-dots';

interface LoadingAnimationProps {
  size?: 'small' | 'medium' | 'large';
}

const LoadingAnimation: React.FC<LoadingAnimationProps> = ({
  size = 'medium',
}) => {
  const imgClass = useMemo(
    () =>
      cn({
        'max-w-8': size === 'small',
        'max-w-14': size === 'medium',
        'max-w-24': size === 'large',
      }),
    [size],
  );

  const textClass = useMemo(
    () =>
      cn('font-medium', {
        'mt-1 w-[5.25rem] text-base': size === 'small',
        'mt-2 w-[6.5rem] text-xl': size === 'medium',
        'mt-4 w-40 text-3xl': size === 'large',
      }),
    [size],
  );

  return (
    <div className='flex flex-col items-center'>
      <img
        src={hounddogLogoBlack}
        alt='loading hounddog logo'
        className={imgClass}
      />
      <div className={cn(`${textClass}`, 'flex')}>
        <h2 className='mr-1'>Loading</h2>
        <LoadingDots />
      </div>
    </div>
  );
};

export default LoadingAnimation;
